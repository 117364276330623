import React, {useEffect, useRef, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import axios from 'axios';

// const url = 'http://localhost';
const Reports = ({reports, theme}) => {
  const url = process.env.REACT_APP_REPORT_URL ?? 'http://localhost';
  const params = useParams();
  const [report, setReport] = useState(null);
  const [symbols, setSymbols] = useState([]);
  const [reportSettings, setReportSettings] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [symbolData, setSymbolData] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * GET to /get-symbols if input value else to /get-all-symbols
   * @param {String || null} inputValue
   * @returns
   */
  const fetchSymbols = async (inputValue, retries = 3) => {
    if (loading) {
      return [];
    }
    setLoading(true);

    try {
      let request = '';
      if (inputValue) {
        request = `/get-symbols`;
      } else {
        request = `/get-all-symbols`;
      }
      const reqFormat = {
        method: 'get',
        url: request,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          group: encodeURIComponent(report?.group),
          token: localStorage.getItem('scanner-sso'),
        },
      };
      if (inputValue) {
        reqFormat.params.symbol = encodeURIComponent(`e${inputValue}`);
      }
      const values = await axios.request(reqFormat);
      if (values?.data?.dataset?.data?.length) {
        const symbolOptions = values?.data?.dataset?.data
          .filter((s) => s?.Symbol?.value && s?.Symbol?.value.charAt(0) === 'e')
          .map((s) => ({value: s?.Symbol?.value, label: s?.Symbol?.value.substr(1)}));
        setSymbols(symbolOptions);
        setLoading(false);

        return symbolOptions;
      }
      setSymbols([]);
      setLoading(false);

      return [];
    } catch (e) {
      console.log('[fetchSymbols] error', e);
      // retry
      if (retries > 0) {
        fetchSymbols(inputValue, retries - 1);
        return [];
      }
      setLoading(false);
      return [];
    }
  };

  /**
   * GET to /get-report
   * @param {String} reportName
   * @returns
   */
  const fetchReports = async (reportName, retries = 3) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.request({
        method: 'get',
        url: `/get-report`,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          group: encodeURIComponent(reportName),
          token: localStorage.getItem('scanner-sso'),
        },
      });
      if (res?.data?.dataset) {
        setLoading(false);
        setReport(res?.data?.dataset);
      }
    } catch (e) {
      console.log('[fetchReports] error', e);
      if (retries > 0) {
        fetchReports(reportName, retries - 1);
        return;
      }
      setLoading(false);
    }
  };

  /**
   * GET to /get-symbol-data
   * @param {String} group
   * @param {String} symbol
   * @returns
   */
  const fetchSymbolData = async (group, symbol, retries = 3) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.request({
        method: 'get',
        url: `/get-symbol-data`,
        baseURL: `${url}/api/reports`,
        timeout: 8000,
        params: {
          group: encodeURIComponent(group),
          symbol: encodeURIComponent(symbol),
          token: localStorage.getItem('scanner-sso'),
        },
      });
      if (res?.data?.dataset?.data) {
        setSymbolData(res?.data?.dataset?.data);
      } else {
        setSymbolData([]);
      }
      if (res.data?.dataset?.settings) {
        setReportSettings(res.data?.dataset?.settings);
      }
      setLoading(false);
    } catch (e) {
      console.log('[fetchSymbolData] error', e);
      // retry
      if (retries > 0) {
        fetchSymbolData(group, symbol, retries - 1);
        return;
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (reports?.length) {
      const matchingReport = reports.find((r) => r?.slug === params?.id);
      if (!matchingReport) {
        return;
      }
      const {groups} = matchingReport;
      if (!groups?.length) {
        return;
      }
      const firstReport = groups[0];
      const reportName = firstReport?.group;
      fetchReports(reportName);
    }
  }, [reports]);

  useEffect(() => {
    if (report) {
      fetchSymbols();
    }
  }, [report]);

  useEffect(() => {
    if (selectedSymbol) {
      const symbol = selectedSymbol?.value;
      fetchSymbolData(report?.group, symbol);
    }
  }, [selectedSymbol]);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <Outlet
        context={{
          report,
          setReport,
          symbols,
          selectedSymbol,
          setSelectedSymbol,
          symbolData,
          fetchSymbols,
          reportSettings,
          theme,
        }}
      />
    </div>
  );
};

export default Reports;
