import React, {useState, useEffect} from 'react';
import {useParams, useOutletContext} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

const NumAbbr = require('number-abbreviate');

const hasNumbers = (t) => {
  const regex = /\d/g;
  return regex.test(t);
};

const displayValue = ({data, dataType}) => {
  if ((!data?.value && data?.value !== 0) || data?.value === '0.00') {
    return <span />;
  }
  const {display} = dataType;
  const {value} = data;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  switch (display) {
    case 'percentage':
      return (value || value === 0) && !Number.isNaN(Number(value)) ? <span>{`${value}%`}</span> : null;
    case 'money-short':
      return value && !Number.isNaN(Number(value)) ? (
        <span>{`$${NumAbbr(Number(value), 2)}`.toUpperCase()}</span>
      ) : null;
    case 'number-short':
      return value && !Number.isNaN(Number(value)) ? <span>{`${NumAbbr(Number(value), 2)}`.toUpperCase()}</span> : null;
    case 'money':
      return value && !Number.isNaN(Number(value)) ? <span>{formatPrice.format(Number(value))}</span> : null;
    case 'number-roundTo':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Number(value).toFixed(2)}`}</span> : null;
    case 'number-round':
      return value && !Number.isNaN(Number(value)) ? <span>{`${Math.round(Number(value))}`}</span> : null;
    default:
      return value && !Number.isNaN(Number(value)) ? <span>{value}</span> : null;
  }
};

const MapTableBody = ({data, dataTypes, yAxisTitle, useYAxisLabel, fillMissingColumns, gridView, order}) => {
  const [dtOrder, setDtOrder] = useState([]);
  if (!dataTypes?.length || !data) {
    return null;
  }
  useEffect(() => {
    if (order?.length) {
      // Sort and Filter for only the dataTypes that are in the order array.
      const ordered = order.map((o) => dataTypes.find((d) => d?.key === o));
      setDtOrder(ordered);
    } else {
      setDtOrder(dataTypes);
    }
  }, [dataTypes, order]);

  return dataTypes?.map((type, index) => {
    const value = data?.[type?.key];

    let missingColumns = Array.isArray(value) ? dataTypes?.length - value?.length : 0;
    if (!value?.length) {
      missingColumns = dataTypes?.length - 1;
    }

    return (
      <tr key={type?.key} className={`${gridView ? 'divide-x divide-gray-300' : ''}`}>
        {yAxisTitle && useYAxisLabel ? (
          index === 0 ? (
            <td className="px-3 py-2 whitespace-nowrap text-sm font-semibold ">{yAxisTitle}</td>
          ) : (
            <td className="px-3 py-2 whitespace-nowrap text-sm font-semibold">
              <span />
            </td>
          )
        ) : null}
        {useYAxisLabel ? (
          <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-[#b2b6ce]">{type?.title}</td>
        ) : null}
        {value?.length && Array.isArray(value) ? (
          value?.map((v) => (
            <td key={`${type?.key}-${v?.value}`} className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
              {displayValue({data: v, dataType: type})}
            </td>
          ))
        ) : (
          <td className="px-3 py-2 whitespace-nowrap text-sm report-item">
            {displayValue({data: value, dataType: type})}
          </td>
        )}
        {missingColumns > 0 && fillMissingColumns
          ? Array.from({length: missingColumns}).map((_, i) => (
              <td
                key={`missing-${type?.key}-${missingColumns - i}`}
                className="px-3 py-2 whitespace-nowrap text-sm font-semibold"
              >
                <span className="invisible">filler</span>
              </td>
            ))
          : null}
      </tr>
    );
  });
};

const RiskRewardTable = ({riskRewardData, riskRewardDataTypes, riskRewardTitle, symbol}) => {
  if (!riskRewardDataTypes?.length || !riskRewardData) {
    return null;
  }
  return (
    <div className="mt-4">
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h1>{riskRewardTitle ?? ''}</h1>
          <h1>{symbol ? symbol?.substr(1) : ''}</h1>
        </div>
      </div>
      <div>
        <table className="min-w-full divide-y divide-gray-300 mt-4">
          <thead>
            <tr className="divide-x divide-gray-200">
              {riskRewardDataTypes?.length
                ? riskRewardDataTypes?.map((type) => (
                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold">
                      {type?.title ?? type}
                    </th>
                  ))
                : null}
            </tr>
          </thead>
          <tbody className="divide-y  divide-gray-200">
            {riskRewardData?.length
              ? riskRewardData?.map((d, i) => (
                  <tr key={`risk-reward-${d?.Symbol?.value}-${i * 5} `} className="divide-x divide-gray-300">
                    {riskRewardDataTypes?.map((type) => {
                      const value = d?.[type?.key];
                      return (
                        <td
                          key={`risk-reward-${type?.key}`}
                          className="px-3 py-2 whitespace-nowrap text-sm report-item"
                        >
                          {displayValue({data: value, dataType: type})}
                        </td>
                      );
                    })}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Report = () => {
  const [riskRewardData, setRiskRewardData] = useState(null);
  const [riskRewardDataTypes, setRiskRewardDataTypes] = useState(null);
  const [riskRewardOrder, setRiskRewardOrder] = useState(null);
  const [riskRewardTitle, setRiskRewardTitle] = useState(null);
  const {report, setReport, symbols, selectedSymbol, setSelectedSymbol, symbolData, fetchSymbols, reportSettings, theme} =
    useOutletContext();
  const formatSymbolData = () => {
    if (!report) {
      if (riskRewardData) setRiskRewardData(null);
      return;
    }
    if (report && Object.keys(report ?? {}).length) {
      if (report && reportSettings &&  reportSettings?.dataType?.length) {
        const riskRewardDTypes = reportSettings?.dataType?.filter((v) => v?.key !== '0');
        // Sort and Filter for only the dataTypes that are in the order array.
        const ordered = report?.asStatsOrder
          ? report?.asStatsOrder.map((o) => riskRewardDTypes.find((d) => d?.key === o))
          : riskRewardDTypes;

        const formattedStats = symbolData?.length
          ? symbolData?.map((ticker) => {
              if (!ticker?.Stats?.length) {
                return null;
              }
              const stats = ticker?.Stats?.map((s) => {
                const keys = Object.keys(s ?? {});
                if (!keys?.length) {
                  return null;
                }
                const formattedKeys = keys.reduce((acc, k) => {
                  const parentKey = k;
                  const foundKey = riskRewardDTypes?.find((d) => d?.key === k);
                  if (foundKey?.type === 'object') {
                    const subKeys = Object.keys(s?.[k] ?? {});
                    if (!subKeys?.length) {
                      return acc;
                    }
                    subKeys.forEach((sk) => {
                      acc[`${parentKey}.${sk}`] = s?.[k]?.[sk] ?? {value: null};
                    });
                    return acc;
                  }
                  return {
                    ...acc,
                    [k]: s?.[k] ?? {value: null},
                  };
                }, {});
                return {...formattedKeys, Symbol: ticker?.Symbol};
              });
              console.log("stats", stats)

              return {...ticker, Stats: stats};
            })
          : [];

        console.log('formattedStats', formattedStats);
        setRiskRewardData(formattedStats?.length ? formattedStats[0]?.Stats : null);
        if (formattedStats?.length) {
          setRiskRewardDataTypes(ordered);
          setRiskRewardOrder(report?.asStatsOrder);
          setRiskRewardTitle(report?.fullTitle);
        }
      }
    }
  };

  const resetData = () => {
    setRiskRewardData(null);
    setRiskRewardDataTypes(null);
    setRiskRewardOrder(null);
    setRiskRewardTitle(null);
  }

  useEffect(() => {
    resetData();
  }, [selectedSymbol]);

  useEffect(() => {
    if (symbolData) {
      console.log('symbolData', symbolData);
      formatSymbolData();
    }
  }, [symbolData]);

  return (
    <div className="w-full mt-4 report">
      {report?.group && symbols?.length ? (
        <Select
          cacheOptions
          options={symbols}
          onChange={(e) => {
            console.log("e", e);
            setSelectedSymbol(e);
          }}
          styles={{
            control: (provided, state) => {
                if (theme === 'dark') {
                    return {
                        ...provided,
                        background: '#1A1B25',
                        border: '1px solid #70769B',
                        color: '#70769B',
                      }
                }
                return {
                    ...provided,
                }
            },
            menu: (provided, state) => {
                if (theme === 'dark') {
                    return {
                        ...provided,
                        background: '#1A1B25',
                        border: '1px solid #70769B',
                        color: '#70769B',
                      }
                }
                return {
                    ...provided,
                }
            },
            Input: (provided, state) => {
                if (theme === 'dark') {
                    return {
                        ...provided,
                        color: '#70769B',
                      }
                }
                return {
                    ...provided,
                    color: '#464b45',
                  
              }
            },
            singleValue: (provided, state) => {
                if (theme === 'dark') {
                    return {
                        ...provided,
                        color: '#70769B',
                      }
                }
                return {
                    ...provided,
                    color: '#464b45',
                  
              }
            },
          }}
        />
      ) : null}
      <div className="report-table">
        <RiskRewardTable
            symbol={selectedSymbol?.value}
          riskRewardData={riskRewardData}
          riskRewardDataTypes={riskRewardDataTypes}
          riskRewardTitle={riskRewardTitle}
        />
      </div>
    </div>
  );
};

export default Report;
